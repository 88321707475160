.home-page {
  height: 60vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 0 1rem;
}
.responsive{
  display: none;
}
.home-page span {
  color: #E0263C;
}

.home-page h1 {
  font-size: 3.15rem;
  font-weight: 900;
  margin-bottom: 1rem; 
}

.home-page p {
  font-size: 1rem;
  line-height: 1.5;
  margin: 0 auto;
  max-width: 600px; 
}

.animated-text {
  display: inline-block;
  white-space: nowrap;
  overflow: hidden;
  border-right: 0.15em solid #CBEEEA;
  animation: typing 3s steps(30), blink-caret 0.75s step-end infinite;
}

@keyframes typing {
  from {
      width: 0;
  }
  to {
      width: 30ch;
  }
}

@keyframes blink-caret {
  from, to {
      border-color: transparent;
  }
  50% {
      border-color: #CBEEEA;
  }
}

@media (max-width: 1200px) {
  .home-page h1 {
      font-size: 3rem;
  }
}

@media (max-width: 992px) {
  .home-page h1 {
      font-size: 2.75rem;
  }
  .home-page p {
      font-size: 0.95rem;
  }
}

@media (max-width: 768px) {
  .home-page h1 {
      font-size: 2.5rem;
  }
  .home-page p {
      font-size: 0.9rem;
  }
}

@media (max-width: 576px) {
  .home-page h1 {
      font-size: 2.25rem;
  }
  .home-page p {
      font-size: 0.85rem;
  }
  .responsive{
    display: block;
  }
  @keyframes typing {
    from {
        width: 0;
    }
    to {
        width: 15ch;
    }
  }
  
}

@media (max-width: 425px) {
  .home-page h1 {
      font-size: 2rem;
  }
  .home-page p {
      font-size: 0.8rem;
  }
}

@media (max-width: 375px) {
  .home-page h1 {
      font-size: 1.75rem;
  }
}

@media (max-width: 320px) {
  .home-page h1 {
      font-size: 1.5rem;
  }
}
