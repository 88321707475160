.mission h3{
    font-weight: 700;
}

@media (max-width: 769px) {
    .mission .description {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 426px) {
    .mission .description {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 376px) {
    .mission .description {
      font-size: 0.8rem;
    }
  }
  