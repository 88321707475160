
.why-us h2,.sub-content h2{
    padding: 10px;
    border-bottom: 5px solid #E0263C;
    width: 310px;
    font-weight: 900;
}
.sub-content h2{
    width: 250px;
}


  
  @media (max-width: 768px) {
   
    .why-us p,.sub-content p {
        font-size: 0.9rem;
    }
  }
  
  @media (max-width: 576px) {

    .why-us p, .sub-content p {
        font-size: 0.85rem;
    }
    
  }
  
  @media (max-width: 425px) {
   
    .why-us p, .sub-content p {
        font-size: 0.8rem;
    }
  }
  
 
  