.terms h3 {
  font-weight: 900;
  border-bottom: 5px solid #e0263c;
  width: 700px;
}
.privacy h3 {
  font-weight: 900;
  border-bottom: 5px solid #e0263c;
  width: 200px;
}

.terms h6,
.privacy h6 {
  font-weight: 700;
}

.terms li,
.privacy li {
  font-size: 1rem;
}
@media (max-width: 769px) {
  .terms h3 {
    width: 680px;
  }
  .terms li,
  .privacy li {
    font-size: 0.9rem;
  }
  .privacy p {
    font-size: 0.95rem;
  }
}

@media (max-width: 426px) {
  .terms h3 {
    width: 400px;
  }
  .terms li,
  .privacy li {
    font-size: 0.85rem;
  }
  .privacy p {
    font-size: 0.9rem;
  }
}

@media (max-width: 376px) {
  .terms h3 {
    width: 350px;
  }
  .terms li,
  .privacy li {
    font-size: 0.8rem;
  }
  .privacy p {
    font-size: 0.85rem;
  }
}
@media (max-width: 320px) {
  .terms h3 {
    width: 310px;
  }
}
