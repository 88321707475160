.logo{
    height: 60px;
    width: 60px;
}
.btn{
    background-color: transparent !important;
    border: 1px solid  #E0263C !important;
    color: #E0263C !important;
    width: 100px !important;
    border-radius: 20px !important;
}
.btn:hover{
    background-color: #E0263C !important;
    color: #ffffff !important;
}
.nav-bar-main{
    box-shadow: 0 4px 2px -2px rgba(0, 0, 0, 0.1);
}
.nav-bar-main a{
    text-decoration: none;
    color: #000;
    padding: 5px 8px;
}
.nav-bar-main span{
    font-weight: 900;
    font-size: 1.75rem;
    font-style: oblique;
}
.nav-bar-main .flex{
    display: flex;
    justify-content: center;
    align-items: center;
}