.features h2{
    padding: 10px;
    border-bottom: 5px solid #E0263C;
    width: 150px;
    font-weight: 900;
}
.features li span{
    font-weight: 700;
    padding: 0 3px;

}
@media (max-width: 769px) {
    .features li  {
      font-size: 0.9rem;
    }
  }
  
  @media (max-width: 426px) {
    .features li  {
      font-size: 0.85rem;
    }
  }
  
  @media (max-width: 376px) {
    .features li  {
      font-size: 0.8rem;
    }
  }
  