.footer {
  background-color: #cbeeea;
}

.bottom-footer {
  border-top: 2px solid #000;
}
.footer .title {
  font-weight: 900;
}
.footer .icon {
  height: 30px;
  padding: 0 3px;
}
.footer-main .image {
  padding: 0;
}

.footer .flex {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
}

.footer .flex a {
  text-decoration: none;
  color: #000;
}
.footer h4 {
  font-weight: 700;
}
@media (max-width: 769px) {
  .footer-main p,
  .footer-main a {
    font-size: 0.9rem;
  }
}

@media (max-width: 426px) {
  .footer-main p,
  .footer-main a {
    font-size: 0.85rem;
  }
}

@media (max-width: 376px) {
  .footer-main p,
  .footer-main a {
    font-size: 0.8rem;
  }
}
