.pricing-card {
  border: none;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
}

.pricing-card-container {
  background-color: #fcfcfc;
}
.pricing-card-container h2 {
  font-weight: 900;
  padding: 10px;
  border-bottom: 5px solid #e0263c;
  width: 150px;
}

@media (max-width: 769px) {
  .pricing-card-container p {
    font-size: 0.9rem;
  }
}

@media (max-width: 426px) {
  .pricing-card-container p {
    font-size: 0.85rem;
  }
}

@media (max-width: 376px) {
  .pricing-card-container p {
    font-size: 0.8rem;
  }
}
